@import 'mixins/a11y-focus-ring.scss';
@import 'mixins/a11y-button-reset.scss';
@import 'mixins/a11y-touch-area.scss';
@import 'breakpoints.scss';

@mixin apply-link-custom-underlined() {
    border-bottom: 1px solid;
    text-decoration: none;
    transition: 0.2s ease color;
}
@mixin apply-link-custom-underlined-hovered() {
    color: var(--color-link-default);
}
@mixin apply-link-decorated() {
    display: inline;
    background-image: linear-gradient(to right, var(--color-link, var(--color-link-default)) 0%, var(--color-link, var(--color-link-default)) 100%);
    background-position: 0 96%;
    background-repeat: repeat-x;
    background-size: 100% 2px;
    transition: 0.1s ease background-position, 0.1s ease opacity;
}

@mixin apply-link-decorated-hovered() {
    --color-link: var(--color-link-hover);

    background-position: 0 106%;
}

@mixin apply-screen-size($size) {
    $breakpoint: map-get($screens, $size);

    @if $breakpoint {
        @media (min-width: $breakpoint) {
            @content;
        }
    }
}

@mixin container {
    display: flex;
    flex-direction: column;
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding-left: var(--content-section-padding-horizontal);
    padding-right: var(--content-section-padding-horizontal);
}
