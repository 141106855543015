.page-feature-cta__container {
    display: flex;
    padding: 64px 16px;
    flex-direction: column;
    justify-items: center;
    width: 100%;
    background: #FFFCF3;

    @include apply-screen-size(desktop-sm) {
        padding: 96px 108px;
    }
}

.page-feature-cta__body {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background: #FFC629;
    border-radius: 24px;

    padding-left: 9px;
    padding-right: 9px;
    padding-top: 56px;
    padding-bottom: 56px;

    @include apply-screen-size(desktop-md) {
        padding-left: 64px;
        padding-right: 64px;
    }
}

.page-feature-cta__header {
    @extend %apply-typography-header-2-responsive;
    @extend %apply-language-based-font-compatibility;

    @include apply-screen-size(desktop-sm) {
        @include apply-typography-header-1(true);

        .page-feature--smaller-title & {
            --font-size: clamp(6px, (16px + 1.57vw) * var(--resize-speed, 1), 100px);
            line-height: 1.1;
        }
    }
    @include apply-screen-size(desktop-md) {
        .page-feature--smaller-title & {
            --font-size: clamp(6px, (6px + 1.57vw) * var(--resize-speed, 1), 100px);
            line-height: 1.1;
        }
    }

    [lang="pl"] & {
        --line-height: 1.1;
    }

    [lang="ja"] & {
        --resize-speed: 0.7;

        font-size: var(--font-size, 32px);

        @include apply-screen-size(desktop-sm) {
            --font-size: clamp(42px, (5.715px + 7.86vw) * var(--resize-speed, 1), 100px);

            font-size: var(--font-size, 32px);
        }

        @include apply-screen-size(desktop-xlg) {
            font-size: 82px;
        }
    }

    /**
      * When we have title in column that is 50%, we decrease font-size by 50%
      */
    [lang="ja"] .columns__item--half & {
        --font-size: calc(var(--font-size) / 2);
    }

    max-width: 700px;
    margin: 0 0 var(--cta-box-spacing-title-text);
    text-wrap: balance;
    text-align: center;
    color: '#343333';
    margin-bottom: 48px;

    &:last-child {
        margin-bottom: 0;
    }
}