.cards {
    --cards-gap-vertical: 24px;
    --cards-gap-horizontal: 24px;

    max-width: 100%;
    gap: var(--cards-gap-vertical) var(--cards-gap-horizontal);
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include apply-screen-size(tablet-sm) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(max(280px, 30%), 1fr));
    }
}

.cards--spacing-loose {
    --cards-gap-vertical: calc(2 * var(--card-content-padding));
}

.cards__item {
    display: flex;
    align-items: stretch;
}
