:root {
    --token-cosmos-button-border-radius-medium: 24px;
    --token-cosmos-button-border-radius-small: 22px;
    --token-cosmos-button-border-width-primary-default: 0px;
    --token-cosmos-button-border-width-primary-inverse: 0px;
    --token-cosmos-button-border-width-secondary-default: 1px;
    --token-cosmos-button-border-width-secondary-inverse: 1px;
    --token-cosmos-button-border-width-tertiary-default: 0px;
    --token-cosmos-button-border-width-tertiary-inverse: 0px;
    --token-cosmos-button-color-background-overlay-default-hover: #1414140D;
    --token-cosmos-button-color-background-overlay-default-pressed: #1414141A;
    --token-cosmos-button-color-background-overlay-inverse-hover: #FFFFFF0D;
    --token-cosmos-button-color-background-overlay-inverse-pressed: #FFFFFF1A;
    --token-cosmos-button-color-background-primary-default: #343333;
    --token-cosmos-button-color-background-primary-inverse: #FFFFFF;
    --token-cosmos-button-color-background-secondary-default: #FFFFFF00;
    --token-cosmos-button-color-background-secondary-inverse: #FFFFFF00;
    --token-cosmos-button-color-background-tertiary-default: #FFFFFF00;
    --token-cosmos-button-color-background-tertiary-inverse: #FFFFFF00;
    --token-cosmos-button-color-border-primary-default: #DCDCDC;
    --token-cosmos-button-color-border-primary-inverse: #DCDCDC;
    --token-cosmos-button-color-border-secondary-default: #343333;
    --token-cosmos-button-color-border-secondary-inverse: #FFFFFF;
    --token-cosmos-button-color-border-tertiary-default: #DCDCDC;
    --token-cosmos-button-color-border-tertiary-inverse: #FFFFFF;
    --token-cosmos-button-color-content-primary-default: #FFFFFF;
    --token-cosmos-button-color-content-primary-inverse: #343333;
    --token-cosmos-button-color-content-secondary-default: #343333;
    --token-cosmos-button-color-content-secondary-inverse: #FFFFFF;
    --token-cosmos-button-color-content-tertiary-default: #343333;
    --token-cosmos-button-color-content-tertiary-inverse: #FFFFFF;
    --token-cosmos-button-sizing-icon-medium: 24px;
    --token-cosmos-button-sizing-max-width: 480px;
    --token-cosmos-button-sizing-min-height-medium: 48px;
    --token-cosmos-button-spacing-gap-medium: 8px;
    --token-cosmos-button-spacing-horizontal-medium: 24px;
    --token-cosmos-button-spacing-vertical-medium: 12px;
}
