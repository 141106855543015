.stack {
    display: inline-flex;
    vertical-align: top;
}

.stack--fullwidth {
    width: 100%;
}

.stack--row {
    flex-direction: row;
}

.stack--column {
    flex-direction: column;
}

.stack--align-start {
    text-align: left;

    &.stack--row {
        justify-content: start;
    }

    &.stack--column {
        align-items: start;
    }
}

.stack--align-center {
    text-align: center;

    &.stack--row {
        justify-content: center;
    }

    &.stack--column {
        align-items: center;
    }
}

.stack--align-end {
    text-align: right;

    &.stack--row {
        justify-content: end;
    }

    &.stack--column {
        align-items: end;
    }
}

.stack--align-stretch {
    text-align: center;

    &.stack--row {
        justify-content: space-between;
    }

    &.stack--column {
        align-items: stretch;
    }
}

.stack__item {
    .stack--row.stack--spacing-compact > & + & {
        margin-left: 8px;
    }

    .stack--row.stack--spacing-default > & + & {
        margin-left: 12px;
    }

    .stack--column.stack--spacing-compact > & + & {
        margin-top: 8px;
    }

    .stack--column.stack--spacing-default > & + & {
        margin-top: 12px;
    }
}
