.icon {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    fill: currentColor;
    -webkit-font-smoothing: antialiased;

    svg {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}

.icon--sm {
    width: 16px;
    height: 16px;
}

.icon--xsm {
    width: 20px;
    height: 20px;
}

.icon--stretch {
    width: inherit;
    height: inherit;
}

.icon--inline {
    display: inline-block;
    height: calc(var(--font-size)* 0.65);
    width: calc(var(--font-size)* 0.65);
    // optical alignment
    margin-bottom: 0.25%;
    margin-left: -1%;
    margin-right: -1%;

    [lang="ja"] & ,
    [lang="pl"] & {
        margin-right: 5px;
    }
    [lang="zh"] & {
        margin-right: 10px;
    }
}
