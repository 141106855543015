.slideover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  }
  
  .slideover__container {
    display: flex;
    background: white;
    height: 100%;
  }
  
  .slideover.display-block {
    display: block;
  }
  
  .slideover.display-none {
    display: none;
  }

  .slideover__body {
    padding: 16px 20px;
  }

  .slideover__footer {
    margin-top: 36px;
    border-top: 1px solid var(--color-border-default);
    padding-top: 24px;
    display: flex;
    padding-bottom: 16px;
  }

// Slideover close button

.slideover__button-container {
  display: flex;
  margin-bottom: 24px;
}

.slideover__button {
  display: flex;
  align-items: center;
}

.slideover__button-text {
  margin-left: 8px;
}

// Slideover cta

.slideover-cta__block {
  display: flex;
  background-color: #FFF4BC;
  border-radius: 16px;
  padding: 40px 30px;
}

.slideover-cta__block-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.slideover-cta__header {
  font-weight: 500;
  margin-bottom: 16px;
};

.slideover-cta__body-text {
  margin: 16px 0;
}

.slideover-cta__button-container {
  display: flex;
}

.slideover-cta__app-container {
  margin-top: auto;
  padding-top: 16px;
}
