.skip-link {
    position: fixed;
    transition: position .2s;
    left: 20px;
    top: -100px;
    z-index: calc(var(--header-z-index) + 1);
}

.skip-link:focus-within {
    top: 20px;

}
