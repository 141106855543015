.page-section {
    display: block;
    position: relative;
}

.page-section__inner {
    @include container;
}

.page-section__picture {
    --page-section-picture-radius: 16px;

    @include apply-screen-size(desktop-sm) {
        --page-section-picture-radius: 24px;
    }

    overflow: hidden;
    position: relative;
    width: 100%;

    img {
        height: 100%;
        max-width: 100%;
    }

    .section-cta & {
        max-width: 600px;
        max-height: 360px;
        aspect-ratio: 320 / 340;
        border-radius: var(--page-section-picture-radius);

        @include apply-screen-size(desktop-sm) {
            aspect-ratio: 600 / 620;
            max-height: min(0.8 * var(--screen-height), 620px);
            max-width: 100%;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .about-hero & {
        max-height: 360px;
        aspect-ratio: 320 / 370;
        border-radius: var(--page-section-picture-radius);

        @include apply-screen-size(desktop-sm) {
            aspect-ratio: 1270 / 520;
            max-height: 520px;
        }
    }
}
