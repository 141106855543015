.header-mobile-menu {
    height: calc(var(--screen-height) - var(--header-height, var(--header-min-height)));
    background: #fff;
    margin-top: var(--header-height, var(--header-min-height));
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    //opacity: 0;
    //visibility: hidden;
    //transition: 0.2s ease opacity, 0.2s ease visibility;
    will-change: opacity, visibility;
    overflow-y: auto;
}

.header-mobile-menu__inner {
    min-height: calc(var(--screen-height) - var(--header-height, var(--header-min-height)));
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: var(--header-padding-horizontal) var(--header-padding-horizontal) var(--header-padding-horizontal);
    justify-content: flex-end;
}

.header-mobile-menu__list {
    width: 100%;
    flex: 1 1 auto;
}

.header-mobile-menu__item {
    & + & {
        margin-top: 16px;
    }
}

.header-mobile-menu__link {
    @extend %apply-typography-header-2-responsive;
    @extend %apply-language-based-font-compatibility;

    text-decoration: none;
    color: var(--color-link-default);
    word-break: break-word;

    &:hover {
        text-decoration: underline;
    }

    .header-mobile-menu__sublist & {
        @extend %apply-typography-action-large-responsive;
    }
}

.header-mobile-menu__sublist {
    padding-left: 40px;
    margin-top: 16px;
}

.header-mobile-menu__social-links {
    margin-top: 40px;
}

.header-mobile-menu__footer {
    margin-top: 36px;
    border-top: 1px solid var(--color-border-default);
    padding-top: 24px;
    display: flex;
}
