.three-image-container {
    display: flex;
    justify-content: center;
}

// Bullet points
.bullet-points__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;

    @include apply-screen-size(desktop-md) {
        flex-direction: row;
    }
}

.bullet-points__text {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;

    @include apply-screen-size(desktop-sm) {
        font-size: 18px;    }
}

.bullet-points__span {
    margin-right: 4px;
}

// Cards

.image-card__container {
    margin-right: 24px;
    align-content: center;

    &:last-child {
        margin-right: 0;
    }
}