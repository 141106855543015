.link {
    display: inline;
    color: var(--color-link-default);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    transition: 0.2s ease opacity;
}

.link--decorated {
    @include apply-link-decorated();

    &:hover {
        @include apply-link-decorated-hovered();
    }
}

.link--underlined {
    @include apply-link-custom-underlined();

    &:hover {
        @include apply-link-custom-underlined-hovered();
    }
}
