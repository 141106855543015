@import 'mixins.scss';
@import 'breakpoints.scss';

:root {
    // DEFAULT COLORS
    --color-text-default: #{$token-cosmos-semantic-color-text-default};
    --color-text-subdued: #{$token-cosmos-semantic-color-text-subdued};
    --color-text-error: #{$token-cosmos-semantic-color-text-error};
    --color-text-inverse: #{$token-cosmos-semantic-color-text-inverse};
    --color-text-brand: #FFF500;
    --color-link-default: #{$token-cosmos-semantic-color-text-links};
    --color-link-hover: #{$token-cosmos-semantic-color-text-links};
    --color-icon-default: #{$token-cosmos-semantic-color-icon-default};
    --color-border-default: #{$token-cosmos-semantic-color-border-default};
    --color-border-selected: #{$token-cosmos-semantic-color-border-selected};

    // FORMS AND CONTROLS
    --forms-border-width-resting: #{$token-cosmos-forms-border-width-default-resting};
    --forms-border-width-focused: #{$token-cosmos-forms-border-width-default-focused};
    --forms-border-color-resting: #{$token-cosmos-forms-color-border-default-resting};
    --forms-border-color-focused: #{$token-cosmos-semantic-color-border-selected};
    --forms-border-color-error: #{$token-cosmos-forms-color-border-error-resting};
    --focus-ring-outer-width: 2px;
    --focus-ring-outer-color: #000;
    --focus-ring-outer-offset: -3px;
    --focus-ring-inner-width: 1px;
    --focus-ring-inner-color: #000;
    --focus-ring-inner-offset: 2px;
    --control-height-medium: 48px;
    --control-padding-horizontal-medium: 24px;

    // TYPOGRAPHY
    --font-family-custom-default: 'BumbleSans', 'BumbleSansFallback', -apple-system, 'San Francisco', 'Helvetica Neue', Roboto, 'Segoe WP', 'Segoe UI', sans-serif;
    --font-family-custom-heading: 'BumbleSansCondensed', 'BumbleSansCondensedFallback', -apple-system, 'San Francisco', 'Helvetica Neue', Roboto, 'Segoe WP', 'Segoe UI', sans-serif;
    --font-weight-regular: normal;
    --font-weight-book: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --font-size-base: 16px;
    --line-height-base: 1.5;

    // LAYOUT
    --header-z-index: 100;
    --over-content-z-index: 10;

    // RESPONSIVE SIZES
    @include responsive-property(content-section-padding-horizontal);
    @include responsive-property(content-section-padding-vertical);
    @include responsive-property(content-section-gap-horizontal);
    @include responsive-property(content-section-gap-vertical);

    --content-max-inner-width: 1218px;
    --content-max-width: calc(var(--content-max-inner-width) + 2 * var(--content-section-padding-horizontal));

    // OTHER
    --logo-color: #{$token-cosmos-semantic-color-icon-default};

    // header
    --header-min-height: 64px;

    @include apply-screen-size(desktop-sm) {
        --header-min-height: 72px;
    }

    // card
    --card-border-radius: 16px;
    --card-content-gap: 12px;

    @include apply-screen-size(desktop-sm) {
        --card-border-radius: 24px;
    }

    @include responsive-property(card-content-padding);
}
