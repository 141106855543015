/*
 * Utility class to expand clickable area (usually used for 'Card' type components)
 * Example: https://inclusive-components.design/cards/
 */

.a11y-stretched-touch {
    @extend %a11y-button-reset;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        cursor: pointer;
    }

    // class .a11y-show-touch-area is used for presentational purposes to highlight touch areas
    .a11y-show-touch-area & {
        box-shadow: inset 0 0 3px rgba(255, 0, 0, 0.7);

        &::before {
            background: rgba(255, 0, 0, 0.1);
        }
    }
}
