@import "../mixins.scss";

.page-footer {
    display: block;
    padding: 32px 0;
}

.page-footer__inner {
    @include container;
}

.page-footer__row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;

    @include apply-screen-size(desktop-sm) {
        gap: 24px;
    }

    & + & {
        margin-top: 24px;
        border-top: 1px solid var(--color-border-default);
        padding-top: 16px;

        @include apply-screen-size(desktop-sm) {
            margin-top: 52px;
            padding-top: 32px;
        }
    }
}

.page-footer__actions {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
    flex: 1000 1 auto;

    .select {
        width: 100%;
    }

    @include apply-screen-size(phone-lg) {
        flex-direction: row;
        align-items: flex-start;

        .select {
            width: fit-content;
        }
    }
}

.page-footer__nav {
    --footer-nav-gap: 24px;
    --footer-nav-list-gap-horizontal: 16px;
    --footer-nav-list-gap-vertical: 8px;

    & + & {
        margin-top: var(--footer-nav-gap)
    }
}

.page-footer__nav-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--footer-nav-list-gap-vertical) var(--footer-nav-list-gap-horizontal);
}

.page-footer__nav-item {
    @extend %apply-typography-action-medium-responsive;

    .page-footer__nav--secondary & {
        @extend %apply-typography-action-small-responsive;
    }
}

.page-footer__nav-link {
    color: var(--color-link-default);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.page-footer__additions {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 1 auto;

    @include apply-screen-size(desktop-sm) {
        align-items: flex-end;
    }
}

.page-footer__navigation {
    flex: 1 1 50%;
}

.page-footer__copy {
    @extend %apply-typography-p-3-responsive;

    font-size: 12px;
    margin-bottom: 0;
    color: var(--color-link-subdued);
    margin-top: 16px;
}
