.header-menu {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: var(--header-menu-gap, 12px);

    @include apply-screen-size(desktop-sm) {
        --header-menu-gap: 24px;
    }
}

.header-menu__item {
    display: flex;
    align-items: center;
}

.header-menu__item {
    position: relative;
}

.header-menu__link {
    @extend %apply-typography-action-small-responsive;

    text-decoration: none;
    color: var(--color-link-default);
    display: flex;
    align-items: center;

    &:hover {
        text-decoration: underline;
    }

    &.is-active {
        font-weight: bolder;
    }

    &:after {
        pointer-events: none;
        content: "";
    }
}

.header-menu__link.has-dropdown:after,
.header-menu__dropdown-toggle {
    display: block;
    margin-bottom: -1px;
    margin-left: 4px;
    border: solid transparent;
    border-width: 6px 6px 0;
    border-top-color: currentColor;
    position: relative;
}

.header-menu__dropdown-toggle {
    @include a11y-touch-area() {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
    };
}

.header-menu__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    padding: var(--header-menu-gap);
    background: #fff;
    margin-left: calc(-1 * var(--header-menu-gap));
    width: max-content;
}

.header-menu__dropdown-item {
    & + & {
        margin-top: calc(var(--header-menu-gap) / 2);
    }
}

.header-menu__dropdown-link {
    @extend %apply-typography-action-small-responsive;

    text-decoration: none;
    color: var(--color-link-default);

    &:hover {
        text-decoration: underline;
    }

    &.is-active {
        font-weight: bolder;
    }
}
