.button {
    --button-overlay-background-hover: #{$token-cosmos-button-color-background-overlay-default-hover};
    --button-overlay-background-pressed: #{$token-cosmos-button-color-background-overlay-default-pressed};

    position: relative;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    min-width: var(--button-min-width);
    min-height: var(--button-min-height);
    margin: 0;
    padding: var(--button-padding-vertical) var(--button-padding-horizontal);
    border: none;
    border: var(--button-border-width, 0) var(--button-border-style, solid) var(--button-border-color, transparent);
    border-radius: calc(var(--button-min-height) / 2);
    background-color: var(--button-background-color, transparent);
    text-align: center;
    color: var(--button-color);
    text-decoration: none;
    transition: 0.2s ease opacity;
    cursor: pointer;
    user-select: none;
    -webkit-font-smoothing: antialiased;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        border-radius: inherit;
    }

    @include apply-screen-size(desktop-sm) {
        --button-min-width: 150px;
    }
}

.button__content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--button-content-color);
    pointer-events: none; // we always want to be sure we "click" the <button> element
    flex: 1 1 auto;
    gap: 0 var(--token-cosmos-button-spacing-gap-medium);
}

.button__text {
    @extend %apply-typography-action-medium-responsive;

    display: block;
    max-width: 100%;
    flex: 0 1 auto;
    min-width: 0;

    @include apply-screen-size(desktop-sm) {
        transform: translateY(-0.05em); // optical alignment
    }
}

.button__icon {
    display: block;
    width: var(--button-icon-size);
    height: var(--button-icon-size);
}

.button__icon + .button__text {
    margin-left: var(--button-gap);
}

.button--circle {
    min-width: var(--button-min-height);
    width: var(--button-min-height);
    height: var(--button-min-height);
    padding: 0;
}

// Sizing
$button-sizes: medium, small;

@each $size in $button-sizes {
    .button--size-#{$size} {
        --button-gap: var(--token-cosmos-button-spacing-#{$size}-gap);
        --button-padding-vertical: var(--token-cosmos-button-spacing-vertical-#{$size});
        --button-padding-horizontal: var(--token-cosmos-button-spacing-horizontal-#{$size});
        --button-border-radius: var(--token-cosmos-button-border-radius-#{$size});
        --button-min-height: var(--token-cosmos-button-sizing-min-height-#{$size});
        --button-icon-size: var(--token-cosmos-button-sizing-icon-#{$size});
        --button-loader-size: var(--token-cosmos-button-sizing-loader-#{$size});
    }
}

// Colors
$button-colors: default, inverse;
$button-styling: primary, secondary, tertiary;

@each $color in $button-colors {
    @each $styling in $button-styling {
        .button--color-#{$color}.button--#{$styling} {
            --button-background-color: var(--token-cosmos-button-color-background-#{$styling}-#{$color});
            --button-color: var(--token-cosmos-button-color-content-#{$styling}-#{$color});
            --button-border-color: var(--token-cosmos-button-color-border-#{$styling}-#{$color});
            --button-border-width: var(--token-cosmos-button-border-width-#{$styling}-#{$color});
        }
    }
}

// States
.button {
    // hack till we have specified reaction tokens for every color
    &[class*='primary'][class*='default'] {
        --button-overlay-background-hover: #{$token-cosmos-button-color-background-overlay-inverse-hover};
        --button-overlay-background-pressed: #{$token-cosmos-button-color-background-overlay-inverse-pressed};
    }

    &:hover::before,
    &:focus-visible::before {
        content: '';
        background-color: var(--button-overlay-background-hover);
    }

    &:active::before,
    &.is-pressed::before {
        content: '';
        background-color: var(--button-overlay-background-pressed);
    }
}

.button--inline {
    max-width: 100%;
    width: max-content;
}

.button--cta-ab-test {
    .ios & {
        display: none;
    }
}

.button--primary.yellow {
    --button-background-color: #FBCA00; 
    --button-content-color: #343333;
}
