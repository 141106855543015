.faq {
    background-color: #FFF4BC;
    display: flex;
    padding: 64px 16px;
    flex-direction: column;
    justify-items: center;
    width: 100%;

    @include apply-screen-size(desktop-sm) {
        padding: 96px 108px;
    }
}

.faq__header {
    @extend %apply-typography-header-2-responsive;
    @extend %apply-language-based-font-compatibility;

    @include apply-screen-size(desktop-sm) {
        @include apply-typography-header-1(true);

        .page__section--smaller-title & {
            --font-size: clamp(6px, (16px + 1.57vw) * var(--resize-speed, 1), 100px);
            line-height: 1.1;
        }
    }
    @include apply-screen-size(desktop-md) {
        .page__section--smaller-title & {
            --font-size: clamp(6px, (6px + 1.57vw) * var(--resize-speed, 1), 100px);
            line-height: 1.1;
            margin-bottom: 130px;
        }
    }

    // margin: 0 0 var(--cta-box-spacing-title-text);
    margin-bottom: 56px;
    text-wrap: balance;
    text-align: center;
}

// Accordian

.accordion {
    border-bottom: 1px solid #343333;
    overflow: hidden;
}
.accordion__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:only-child {
        margin-bottom: 16px;
    }
}

.accordion__title {
    font-size: 18px;
    font-weight: 500;
    display: block;
    height: 100%;
    pointer-events: none;

    // Fix for line-height issue in some browsers
    [lang="hi"] & {
        line-height: 1.5;
    }
}

.accordion__close {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 24px;
    height: 24px;

    .header-3 {
        display: block;
        width: inherit;
        height: inherit;
        line-height: 24px;
        font-size: 32px; // optical alignment
    }
}

.accordion__content {
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 400;

    a {
        color: inherit;
        text-decoration: underline;
    }

    // Fix for line-height issue in some browsers
    [lang="hi"] & * {
        line-height: 1.5;
    }
}

.accordion__header + .accordion__content {
    margin-top: 16px;
}

.accordion + .accordion {
    margin-top: 32px;
}