.carousel-card {
    --aspect-ratio: 1.5;

    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: var(--card-border-radius);
    width: 100%;

    @include apply-screen-size(desktop-sm) {
        flex-direction: row;
        align-items: stretch;
    }
}

.carousel-card__image {
    flex: 0 1 auto;
    position: relative;
    overflow: hidden;
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;

    > img {
        width: 100%;
        height: max(100%, calc(100% / var(--aspect-ratio)));
        display: block;
        object-fit: cover;
        aspect-ratio: var(--aspect-ratio);

        @include apply-screen-size(desktop-sm) {
            min-height: 240px;
            aspect-ratio: unset;
            position: absolute;
            top: 0;
        }
    }

    @include apply-screen-size(desktop-sm) {
        aspect-ratio: var(--aspect-ratio);
        height: 100%;
        flex: 1 1 auto;
        border-radius: var(--card-border-radius) 0 0 var(--card-border-radius);
        width: 50%;
        min-height: 240px;
    }
}

.carousel-card__content {
    padding: var(--card-content-padding);
    background: #fff;
    border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
    flex: 1 1 auto;

    @include apply-screen-size(desktop-sm) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-radius: 0 var(--card-border-radius) var(--card-border-radius) 0;
        justify-content: center;
        width: 50%;
    }
}

.carousel-card__title {
    @extend %apply-typography-header-3-responsive;

    color: var(--color-link-default);
    word-break: break-word;
}

a.carousel-card__title {
    text-decoration: none;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &:hover {
        text-decoration: underline;
    }
}

.carousel-card__text {
    @extend %apply-typography-p-2-responsive;

    display: block;
    position: relative;
    word-break: break-word;
}

.carousel-card__title + .carousel-card__text {
    margin-top: var(--card-content-gap);
}
