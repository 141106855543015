
.select {
    --select-min-height: var(--control-height-medium);
    --select-label-color: var(--color-text-default);

    --select-border-radius: calc(var(--select-min-height) / 2);
    --select-background: #fff;
    --select-padding-horizontal: 24px;
    --select-padding-vertical: 8px;
    --select-gap: 8px;
    --select-icon-start-size: 20px;
    --select-icon-end-size: 20px;
    --select-icon-start-color: #000;
    --select-icon-end-color: #000;
    --select-border-color: var(--forms-border-color-resting);
    --select-border-width: var(--forms-border-width-resting);

    --select-dropdown-gap: 16px;
    --select-dropdown-padding-hozirontal: 24px;
    --select-dropdown-padding-vertical: 16px;

    display: block;
    color: var(--select-label-color);
    width: fit-content;
    min-width: min(280px, 100%);
    max-width: 100%;
    position: relative;
    min-height: var(--select-min-height);
    user-select: none;

    &.is-opened {
        .select-label__icon-end {
            transform: scale(-1);
        }

        &:not(.direction-top) {
            .select-label {
                border-radius: var(--select-border-radius) var(--select-border-radius) 0 0;
                border-bottom-color: transparent;
            }

            .select-dropdown {
                top: calc(100% - 7px);
                border-top: 0;
                border-radius: 0 0 var(--select-border-radius) var(--select-border-radius);
            }
        }

        &.direction-top {
            .select-label {
                border-radius: 0 0 var(--select-border-radius) var(--select-border-radius);
                border-top-color: transparent;

                &::after {
                    bottom: initial;
                    top: 0;
                }
            }
        }

        .select-dropdown {
            display: block !important;
        }
    }

    &.direction-top {
        .select-dropdown {
            bottom: calc(100% - 7px);
            border-bottom: none;
            border-radius: var(--select-border-radius) var(--select-border-radius) 0 0;
        }
    }
}

.select-dropdown {
    position: absolute;
    z-index: var(--over-content-z-index);
    background: #FFF;
    border: var(--select-border-width) solid var(--select-border-color);
    left: 0;
    right: 0;
    overflow: hidden;
}

.select-dropdown__list {
    list-style: none;
    margin: 0;
    padding: var(--select-dropdown-padding-vertical) var(--select-dropdown-padding-hozirontal);
    overflow: auto;
    max-height: inherit;
}

.select-dropdown__item {
    & + & {
        margin-top: var(--select-dropdown-gap);
    }
}

.select-dropdown__link {
    width: 100%;
    display: block;
    text-decoration: none;
}

.select-label {
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--select-padding-vertical) var(--select-padding-horizontal);
    cursor: pointer;
    border: var(--select-border-width) solid var(--select-border-color);
    border-radius: var(--select-border-radius);
    gap: var(--select-gap);
    min-height: var(--select-min-height);
}

.select-label__icon-start {
    width: var(--select-icon-start-size);
    height: var(--select-icon-start-size);
    display: flex;
    flex: 0 0 auto;
    color: var(--select-icon-start-color);
}

.select-label__icon-end {
    width: var(--select-icon-end-size);
    height: var(--select-icon-end-size);
    display: flex;
    flex: 0 0 auto;
    color: var(--select-icon-end-color);
}

.select-label__text {
    @extend %apply-typography-action-small-responsive;

    flex: 1 1 auto;
    min-width: 0;
}

.select-dropdown--has-max-height {
    max-height: 280px;
}

.select-dropdown__link {
    @extend %apply-typography-action-small-responsive;

    color: var(--color-link-default);
    text-decoration: none;
}
