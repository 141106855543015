.social-links {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 16px;
    min-width: 0;
    margin: 0;
    padding: 0;
    list-style: none;

    @include apply-screen-size(desktop-md) {
        gap: 8px 24px;
    }
}

.social-links__link {
    display: flex;
    color: var(--color-icon-default);
    text-decoration: none;
}

.social-links__icon {
    width: 24px;
    height: 24px;
}
