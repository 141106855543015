.header {
    --header-padding-horizontal: 16px;
    --header-actions-gap-horizotal: 12px;
    --header-bg-color: #fff;
    --logo-width: 130px;
    --logo-min-width: 130px;

    @include apply-screen-size(desktop-sm) {
        --header-padding-horizontal: 24px;
        --header-actions-gap-horizotal: 24px;
        --logo-width: 175px;
    }

    position: sticky;
    z-index: var(--header-z-index);
    top: calc(-1 * var(--header-height, var(--header-min-height)));
    transition: 0.3s top;
    background: var(--header-bg-color, #fff);

    .is-opened-mobile-nav &,
    &.is-visible {
        top: 0;
    }
}

.header__inner {
    min-height: var(--header-min-height);
    display: flex;
    align-items: center;
    padding: 0 var(--header-padding-horizontal);
    margin: 0 auto;
    max-width: var(--content-max-width);

    .is-opened-mobile-nav & {
        padding-right: calc(var(--scrollbar-width) + var(--header-padding-horizontal));
    }
}

.header__actions {
    display: flex;
    align-items: center;
}

.header__logotype {
    display: flex;
    flex: 1 1 auto;
    align-self: center;
    max-width: var(--logo-width);
    min-width: var(--logo-min-width);
}

.header__logotype-link {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    aspect-ratio: 130 / 23;
}

.header__logotype + .header__actions {
    margin-left: auto;
}

.header__mobile-menu-toggle {
    margin-left: auto;

    > .button {
        margin-right: calc(-1 * (var(--button-min-height) - var(--button-icon-size)) / 2)
    }
}

.header__menu {
    margin-left: auto;
}
