.page {
    display: flex;
    flex-direction: column;
    min-height: var(--screen-height);
}

.page__section {
    display: block;

    &:empty {
        display: none;
    }
}

.page__section--padding-top,
.page__section--padding-block {
    padding-top: var(--content-section-padding-vertical);
}

.page__section--padding-bottom,
.page__section--padding-block {
    padding-bottom: var(--content-section-padding-vertical);
}

.page__section-inner {
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding: 0 var(--content-section-padding-horizontal);
    overflow-x: hidden;

    .page__section--mobile-full-width & {
        @include apply-screen-size(tablet-md) {
            padding: 0 var(--content-section-padding-horizontal);
        }

        padding: 0;
    }

    &.is-action--more-gap {
        .cta-box {
            --cta-box-spacing-actions: 32px;

            @media screen and (min-width: 660px) {
                --cta-box-spacing-actions: 52px;
            }

            .cta-box__content {
                margin-top: var(--cta-box-spacing-actions);
            }
        }
    }
}

.page__section-expander {
    margin: 0 calc(-1 * var(--content-section-padding-horizontal));
}

.page__section--full-width {
    width: 100%;
}

.page__section--peach {
    background: #FFF4BC;
}

.page__section--yellow {
    background: #FFF500;
}

.page__section--orange {
    background: #FFC629;
}

.page__section--white {
    background: #FFF;
}

.page__section--overlay {

}

.page__section--relative {
    position: relative;

    .page__section-inner {
        position: relative;
    }
}

.page__section--overflow {
    overflow: hidden;
}

.page__section--overlay {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: var(--background-image);
        background-repeat: repeat;
        pointer-events: none;
    }
}

.page__section--hero-half-height {
    max-height: var(--max-height);
    overflow: hidden;
}

.page__section--max-width {
    --content-section-padding-vertical: 32px;
    max-width: var(--content-max-width);
    margin: 0 auto;

    .page__section-background-text-image {
        --margin-top:calc(var(--info-graphic-title-height) + 100px) !important;
        --min-height: 69%;

        @media screen and (min-width: 1320px) {
            --margin-top: 20px;
        }

        @media screen and (min-width: 1420px) {
        --margin-top: 0;
        --min-height: 96%;
        }
    }

    .page__section-inner-image {
        --max-height: calc(100vh + var(--header-min-height));
        --min-height: calc(100vw * 1.4);

        @media screen and (min-height: 780px) {
            --min-height: calc(100vw * 1.69);
        }

        @media screen and (min-width: 660px) {
            --min-height: calc(100vw * 1.69);
        }
        @media screen and (min-width: 780px) {
            --min-height: calc(100vw * 0.51);
        }
        @media screen and (min-width: 1420px) {
            --min-height: 730px !important;
        }
    }

}

.page__section--mobile-select-full-width {
    .select {
        width: 100%;

        @include apply-screen-size(navigation-full) {
            width: fit-content;
        }
    }
}