//
// Error block
//
//

// Elements
//
.error-block {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;

    .ie11 & {
        min-height: 60vh;
    }
}

.error-block__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100%;
}

.error-block__title {
    max-width: 100%;
    color: var(--color-primary);
    font-family: var(--font-family-bold);
}

.error-block__description {
    max-width: 100%;
}

.error-block__title-code {
    font-size: 48px;
    line-height: 1;

    @media screen and (min-width: 680px) {
        font-size: 96px;
    }
    @media screen and (min-width: 960px) {
        font-size: 144px;
    }
}

.error-block__title-text {
    font-size: 18px;
    line-height: 1.2;

    @media screen and (min-width: 680px) {
        font-size: 24px;
    }
    @media screen and (min-width: 960px) {
        font-size: 32px;
    }
}

.error-block__actions {
    display: flex;
    justify-content: center;
    align-items: center;
}

// Relationships
//
.error-block__title-code + .error-block__title-text {
    margin-top: 8px;

    @media screen and (min-width: 680px) {
        margin-top: 32px;
    }
}

.error-block__title + .error-block__description {
    margin-top: 16px;

    @media screen and (min-width: 680px) {
        margin-top: 32px;
    }
}

.error-block__description + .error-block__actions {
    margin-top: 36px;
}

// Modificators
//
.error-block--unsupported-browsers {
    max-width: 416px;
    margin: 0 auto;

    .error-block__title {
        color: var(--color-primary);
    }

    .error-block__title-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.2;

        @media screen and (min-width: 680px) {
            font-size: 24px;
        }
        @media screen and (min-width: 960px) {
            font-size: 24px;
        }
    }

    .error-block__description {
        letter-spacing: 0.03em;
        font-size: 16px;
        line-height: 1.5;
    }

    .error-block__title + .error-block__description {
        margin-top: 12px;
    }
}
