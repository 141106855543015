html {
    --screen-height: 100vh;

    @supports(height: 100dvh) {
        --screen-height: 100dvh;
    }

    color: var(--color-text-default);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-family: var(--font-family-custom-default), sans-serif;
}

.is-opened-mobile-nav {
    overflow: hidden;
    height: var(--screen-height);
}
