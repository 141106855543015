.card {
    --card-image-max-width: 420px;

    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
}

.card__header {
    min-height: 32px;
    width: 100%;
}

.card__image {
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: var(--card-border-radius);
    max-width: var(--card-image-max-width);
    flex: 0 0 auto;

    > img {
        width: 100%;
        height: auto;
        display: block;
        aspect-ratio: 1.5;
        object-fit: cover;
    }
}

.card__content {
    padding-top: var(--card-content-padding);
    flex: 1 1 auto;
}

.card__title {
    @extend %apply-typography-header-3-responsive;

    color: var(--color-link-default);
}

a.card__title {
    text-decoration: none;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &:hover {
        text-decoration: underline;
    }
}

.card__text {
    @extend %apply-typography-p-2-responsive;

    display: block;
    position: relative;

    a {
        color: inherit;
    }
}

.card__title + .card__text {
    margin-top: var(--card-content-gap);
}

.card--filled {
    border-radius: var(--card-border-radius);
    max-width:  var(--card-image-max-width);

    .card__content {
        padding: var(--card-content-padding);
        background: #fff;
        border-bottom-left-radius: var(--card-border-radius);
        border-bottom-right-radius: var(--card-border-radius);
    }

    .card__image {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.card--modal {
    .card__header {
        border-radius: 16px 16px 0 0;
        border: #DCDCDC 1px solid;
        display: flex;
        justify-content: space-between;
        padding: 12px;
    }

    .card__content {
        text-align: left;
        padding: 36px 32px;
        border-radius:  0 0 16px 16px;
        border: #DCDCDC 1px solid;
        border-top-width: 0;
        background-color: #fff;
    }

    .card__title {
        display: inline-block;
        background-color: #343333;
        color: #FFFFFF;
        text-transform: uppercase;
        font-family: var(--font-family-custom-heading);
        line-height: calc(100% + 4px * 2);
        padding: 2px 6px 4px;
    }
}
