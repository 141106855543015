.hero-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: grid;
    grid-template-areas: 'overlay';
    max-height: max(calc(var(--screen-height) - var(--header-height, var(--header-min-height))), fit-content);
    min-height: max(calc(var(--screen-height) - var(--header-height, var(--header-min-height))), 300px);
    height: calc(100% / var(--aspect-ratio));
    background: lightgray;

    //&::before {
    //    display: block;
    //    padding-bottom: calc(100% / var(--aspect-ratio));
    //    content: '';
    //    grid-area: overlay;
    //}
}

.hero-image--full-screen {
    height: auto;
}

.hero-image--front-filter {
    .hero-image__overlay {
        background: rgba(0, 0, 0, 0.3);
    }
}

.hero-image__media {
    position: relative;
    grid-area: overlay;

    // need it only for sandbox where CDNImage doesn't work
    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.hero-image__overlay {
    grid-area: overlay;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
    padding: var(--content-section-padding-vertical) var(--content-section-padding-horizontal);
}
