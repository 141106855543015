.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .modal__container {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: calc(72px + 20px);
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .modal.display-block {
    display: block;
  }
  
  .modal.display-none {
    display: none;
  }

  .modal__body {
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .modal__navigation-list {
    display: flex;
    grid-column: span 6 / span 6;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 58px;
    padding-bottom: 48px;

    @include apply-screen-size(desktop-md) {
      grid-column: span 7 / span 7;
    }

    @include apply-screen-size(desktop-lg) {
      padding-bottom: 198px;
      grid-column: span 8 / span 8;
    }
  }

  //  Modal list

  .modal-list__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

      @include apply-screen-size(desktop-lg) {
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
      }
  }

  .modal-list__item {
    margin-bottom: 25px;
  }

  .modal-list__dropdown-link {
    @extend %apply-typography-action-large-responsive;

    text-decoration: none;
    color: var(--color-link-default);

    &:hover {
        text-decoration: underline;
    }

    &.is-active {
        font-weight: bolder;
    }
  }

  .modal-list__description {
    font-size: 14px;
  }

// Modal close button

.modal__button-container {
  position: absolute;
  top: 20px;
  left: 23px;
}

// Modal cta

.modal-cta__block {
  display: flex;
  flex: 1;
  background-color: #FFF4BC;
  grid-column: span 6 / span 6;
  border-radius: 0 16px 16px 0;
  padding: 40px 30px;

  @include apply-screen-size(desktop-md) {
    grid-column: span 5 / span 5;
  }

  @include apply-screen-size(desktop-lg) {
    grid-column: span 4 / span 4;
  }
}

.modal-cta__block-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-cta__header {
  font-weight: 500;
  margin-bottom: 16px;
};

.modal-cta__body-text {
  margin: 16px 0;
}

.modal-cta__button-container {
  display: flex;
}

.modal-cta__app-container {
  margin-top: auto;
  padding-top: 16px;
}