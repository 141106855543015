.color-default {
    color: var(--color-text-default);
}

.color-inverse {
    color: var(--color-text-inverse);
}

.color-brand {
    color: var(--color-text-brand);
}
