@import 'typography.reference.scss';

.is-responsive {
    // safari clamp bug
    // https://stackoverflow.com/questions/37070694/font-size-calc-dynamic-size-safari-failing
    // still works not all the time and need investigation
    min-height: 0vw;
}

.header-1 {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @extend %apply-language-based-font-compatibility;
    @include apply-typography-header-1();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-header-1-responsive;
    }
}

.header-2 {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @extend %apply-language-based-font-compatibility;
    @include apply-typography-header-2();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-header-2-responsive;
    }
}

.header-3 {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @include apply-typography-header-3();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-header-3-responsive;
    }
}

.p-1 {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @include apply-typography-p-1();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-p-1-responsive;
    }
}

.p-2 {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @include apply-typography-p-2();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-p-2-responsive;
    }
}

.p-3 {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @include apply-typography-p-3();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-p-3-responsive;
    }
}

.action-small {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @include apply-typography-action-small();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-action-small-responsive;
    }
}

.action-medium {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @include apply-typography-action-medium();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-action-medium-responsive;
    }
}

.action-large {
    @extend %apply-text-inherited;
    @extend %apply-text-pallete;
    @include apply-typography-action-large();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @extend %apply-typography-action-large-responsive;
    }
}

.is-italic {
    margin-right: 0.2ch;
    font-style: italic;
}

.formatted-text {
    font-family: var(--font-family-custom-default), sans-serif;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        font-size: 1em;
        line-height: 1.4;
    }

    b + *,
    strong + *,
    p + * {
        margin-top: 1em;
    }

    // styles from lever
    b[style] {
        font-size: inherit !important;
    }

    ul,
    ol {
        padding-left: 0;
    }

    ol li + li,
    ul li + li {
        margin-top: 11px;
    }
}

.is-one-line {
    white-space: nowrap;
}

// Debug
@mixin apply-typography-debugger($color, $name) {
    position: relative;
    background-color: rgba($color, 0.1);

    &::after {
        position: absolute;
        top: -10px;
        left: 0;
        content: '#{$name}';
        color: #999;
        font-size: 10px;
        line-height: 1;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
            Arial, sans-serif;
    }
}

//.title { @include apply-typography-debugger(#0D47A1, 'title') }
//.h1 { @include apply-typography-debugger(#1565C0, 'h1') }
//.h2 { @include apply-typography-debugger(#1976D2, 'h2') }
//.h3 { @include apply-typography-debugger(#1E88E5, 'h3') }
//.p-1 { @include apply-typography-debugger(#2196F3, 'p1') }
//.p-2 { @include apply-typography-debugger(#42A5F5, 'p2') }
//.p-3 { @include apply-typography-debugger(#64B5F6, 'p3') }
