.carousel:not([data-keen-slider-disabled]) {
    position: relative;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
}

.carousel__content {
    padding: 0 var(--content-section-padding-horizontal);
    position: relative;
}

.carousel__slides {
    width: 100%;
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: var(--card-border-radius);

    .carousel[data-keen-slider-reverse] & {
        flex-direction: row-reverse;
    }
}

.carousel__slide {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: stretch;
}

.carousel__nav {
    position: absolute;
    top: 0;
    bottom: 0;
    width: var(--content-section-padding-horizontal);
    display: flex;
    align-items: center;
    z-index: 1;
}

.carousel__nav--prev {
    left: 0;
    justify-content: flex-start;

    @include apply-screen-size(desktop-sm) {
        justify-content: center;
    }
}

.carousel__nav--next {
    right: 0;
    justify-content: flex-end;

    @include apply-screen-size(desktop-sm) {
        justify-content: center;
    }
}

.carousel__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    gap: 8px;
}

.carousel__dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid var(--color-border-selected);


    &.is-active {
        background: var(--color-border-selected);
    }
}
